<template>
  <div>
    <div class="">
      <button class="resolution-btn" :class="{ active: hd }" @click="toggleHD(true, options.videos.hiRes.videoUrl)">720p</button>
      <button class="resolution-btn" :class="{ active: !hd }" @click="toggleHD(false, options.videos.lowRes.videoUrl)">360p</button>
    </div>
    <div class="video-player-wrapper relative">
      <video ref="videoPlayer" class="video-js" oncontextmenu="return false;" webkit-playsinline="true" playsinline="true"></video>
    </div>
  </div>
</template>
<script>
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
export default {
  name: 'VideoPlayer',
  props: {
    options: {
      type: Object,
      default() {
        return {}
      }
    },
    enableControl: Boolean,
    toPause: {
      type: Boolean
    }
  },
  watch: {
    toPause: function (newVal, oldVal) {
      this.pauseVideo()
    }
  },
  data() {
    return {
      player: null,
      previousTime: 0,
      hd: true,
      currentSrc: ''
    }
  },
  mounted() {
    this.currentSrc = this.options.videos.hiRes.videoUrl
    const self = this
    this.player = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady() {
      var player = this
      player.on('ended', function () {
        self.triggerAction('FINISH')
      })
      player.on('play', function () {
        self.triggerAction('PLAY')
      })
      if (!self.enableControl) player.controlBar.progressControl.disable()
    })
  },
  methods: {
    triggerAction(action) {
      this.$emit('action', action)
    },
    pauseVideo() {
      if (this.toPause) {
        this.player.pause()
      }
    },
    play() {
      this.player.src(this.currentSrc)
      this.player.play()
    },
    toggleHD(bool, src) {
      this.currentSrc = src
      const isPaused = this.player.paused()
      this.player.pause()
      const currentTime = this.player.currentTime()
      this.hd = bool
      this.player.src(src)
      this.player.currentTime(currentTime)
      if (!isPaused) {
        this.player.play()
      }
    }
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose()
    }
  }
}
</script>
<style lang="scss" scoped>
.video-player {
  &-wrapper {
    overflow: hidden;
    position: relative;
    &::v-deep(.vjs-big-play-button) {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    &::v-deep(.vjs-poster) {
      background-size: cover;
    }
  }
}
.resolution-btn {
  background-color: rgba(0, 0, 0, 0.5);
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
}

.active {
  background-color: rgba(0, 0, 0, 0.8);
}

.loader-bg {
  background-color: rgba(0, 0, 0, 0.7);
}
</style>
